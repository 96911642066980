import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useUserContext } from '../contexts'
import * as ga from '../lib/ga'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { validatePassword } from '../validators/password.validator'
import Checkbox from '../components/shared/checkbox'
import PasswordStrengthChecker from '../components/shared/password-strength-checker'
import PasswordRulesInformation from '../components/form/password-rules-information'

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $oldPassword: String!
    $newPassword: String!
    $repeatPassword: String!
  ) {
    appUpdatePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      repeatPassword: $repeatPassword
    )
  }
`

function UpdatePasswordPage() {
  const navigate = useNavigate()
  const { t } = useTranslation('userSettings')
  const { updateUser } = useUserContext()
  const [errorName, setErrorName] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [passwordWarning, setPasswordWarning] = useState('')
  const [validatorResults, setValidatorResults] = useState({})

  const tempStorage = window.sessionStorage

  let formNewPassword
  let formRepeatPassword

  const [appUpdatePassword, { loading: updatePasswordLoading }] = useMutation(
    UPDATE_PASSWORD,
    {
      onCompleted() {
        tempStorage.removeItem('pass')

        updateUser({
          isFirstLogin: false
        })

        navigate('/accept')
      },
      onError(err) {
        const error = `${err}`.split(':').reverse()[0]
        setErrorName(error)
      }
    }
  )

  const checkPassword = () => {
    let hasDisabledSubmit = true
    const password = formNewPassword.value
    const repeatPassword = formRepeatPassword.value
    setPassword(password)

    const passwordValidator = validatePassword(password, repeatPassword, t)

    if (!passwordValidator.isValid) {
      if (!passwordValidator.results.identicalMatch) {
        setPasswordWarning(passwordValidator.errors[0])
      } else {
        setPasswordWarning(null)
      }
      hasDisabledSubmit = true
    } else {
      setPasswordWarning(null)
      hasDisabledSubmit = false
    }
    if (password.length !== repeatPassword.length) {
      hasDisabledSubmit = true
    }
    setDisabledSubmit(hasDisabledSubmit)
    setValidatorResults(passwordValidator.results)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const variables = {
      oldPassword: tempStorage.getItem('pass'),
      newPassword: formNewPassword.value,
      repeatPassword: formRepeatPassword.value
    }

    appUpdatePassword({
      variables
    })

    formNewPassword.value = ''
    formRepeatPassword.value = ''
    setErrorName(null)
    ga.event({
      action: 'login_update_password'
    })
  }

  // TODO: Show Accept overlay
  // When clicked accept, mutation to db
  // Redirect on ok aswer from mutation
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t('createPassword')}
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="formNewPassword" className="sr-only">
                  {t('newPasswordDescription')}
                </label>
                <input
                  id="formNewPassword"
                  name="formNewPassword"
                  type={showPassword ? 'text' : 'password'}
                  onChange={checkPassword}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder={t('cardChangePassword.newPassword')}
                  ref={(node) => {
                    formNewPassword = node
                  }}
                />
              </div>
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="formConfirmNewPassword"
                  name="formConfirmNewPassword"
                  type={showPassword ? 'text' : 'password'}
                  onChange={checkPassword}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder={t('cardChangePassword.repeatPassword')}
                  ref={(node) => {
                    formRepeatPassword = node
                  }}
                />
              </div>
            </div>
            {passwordWarning && (
              <div className="row-span-1 sm:row-span-1 text-sm text-red-500 flex gap-1 items-center font-semibold">
                <ExclamationCircleIcon className={'w-[18px]'} /> {passwordWarning}
              </div>
            )}
            <Checkbox
              label={t('cardChangePassword.showPassword')}
              onChange={() => {
                setShowPassword(!showPassword)
              }}
            />
            <PasswordStrengthChecker password={password} />
            <PasswordRulesInformation validatorResults={validatorResults} />
            <div>
              <button
                type="submit"
                disabled={!!updatePasswordLoading || disabledSubmit}
                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  disabledSubmit && 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                {t('passwordReset:form.btnSaveNewPassword')}
              </button>
            </div>

            {errorName && (
              <div className="rounded-md bg-red-50 border border-red-300 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      Det var ett fel i formuläret
                      {'fix this'}
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul role="list" className="list-disc pl-5 space-y-1">
                        <li>{errorName}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

export default UpdatePasswordPage
