import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Loader from '../components/loader'
import { PageBody } from '../components/page-body'
import * as ga from '../lib/ga'
import stylesForm from '../components/form.module.css'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { MailIcon } from '@heroicons/react/solid'

const REQUEST_RESET_PASSWORD = gql`
  mutation RequestPasswordReset($email: String!) {
    appRequestPasswordReset(email: $email)
  }
`

function RequestPasswordResetPage() {
  const [errorName, setErrorName] = useState(null)
  const { t } = useTranslation('forgotPassword')

  let email
  const [appRequestPasswordReset, { data: resetData, loading: resetLoading }] =
    useMutation(REQUEST_RESET_PASSWORD, {
      onError(err) {
        const error = `${err}`.split(':').reverse()[0]
        setErrorName(error)
      }
    })

  if (resetLoading) {
    return <Loader />
  }

  if (resetData) {
    return (
      <div className="flex flex-col items-center justify-center bg-gray-50 p-4 py-10">
        <div className="rounded-lg  p-6 max-w-md text-center">
          <MailIcon className="h-16 w-16 text-blue-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold mb-2 text-gray-800">{t('title')}</h1>
          <p className="text-lg text-gray-700 mb-4">{t('linkSent')}</p>
          <p className="text-gray-600">{t('linkSentClick')}</p>
          <div className="mt-6">
            <a
              href="/"
              className="text-blue-600 font-semibold hover:text-blue-700 underline flex items-center gap-2 justify-center"
            >
              {/* // TODO: add translation for this string */}
              <ChevronLeftIcon className={'w-6'} /> Go home
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <PageBody>
      <div className={'mx-auto max-w-[500px] max-md:w-full max-md:px-2'}>
        <h1 className={'text-4xl font-medium'}>
          <span>{t('title')}</span>
        </h1>
        <p className={'my-8'}>
          {/* // TODO: Add translations for this */}
          Enter the email address associated with your account and we will send you link
          to reset password.
        </p>
      </div>

      <form
        className={
          'mx-auto max-w-[500px] max-md:w-full my-10 p-8 bg-white shadow rounded-md'
        }
        onSubmit={(e) => {
          e.preventDefault()
          appRequestPasswordReset({
            variables: { email: email.value }
          })
          email.value = ''
          setErrorName(null)

          ga.event({
            action: 'request_password_reset'
          })
        }}
      >
        <div className={stylesForm.inputrow}>
          <label htmlFor="email" className={stylesForm.label}>
            {t('content:email')}
          </label>
          <input
            id="email"
            type="email"
            required
            className={
              'appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm'
            }
            ref={(node) => {
              email = node
            }}
          />
        </div>

        <div className={stylesForm.submitBtnWrap}>
          <button
            type="submit"
            className={
              'group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            }
          >
            {t('btnResetPassword')}
          </button>
        </div>

        {errorName && (
          <div style={{ textAlign: 'center', color: 'red', padding: '10px' }}>
            {errorName}
          </div>
        )}
      </form>
      <a
        className={
          'flex justify-center items-center font-medium text-blue-600 pt-5 pb-10'
        }
        href="/login"
      >
        <ChevronLeftIcon className={'w-6'} /> Back to login
      </a>
    </PageBody>
  )
}

export default RequestPasswordResetPage
