export const getUser = () => {
  if (window.localStorage.businessWithUser) {
    const parsedUserObject = JSON.parse(window.localStorage.businessWithUser)
    const { userId, systemId, companyId } = parsedUserObject

    if (userId === 'no-user') {
      parsedUserObject.userId = null
    }

    if (companyId === 'no-company') {
      parsedUserObject.companyId = null
    }

    if (systemId === 'no-system') {
      parsedUserObject.systemId = null
    }

    return parsedUserObject
  }

  return {}
}

const setUser = (user) => {
  window.localStorage.businessWithUser = JSON.stringify(user)
  window.dispatchEvent(new Event('storage'))
  return user
}

export const handleLogin = (loginObj) => {
  return setUser({
    id: loginObj.user.id,
    name: loginObj.user.name,
    email: loginObj.user.email,
    role: loginObj.user.role,
    appRole: loginObj.user.appRole,
    company: loginObj.user?.vendor?.company,
    systems: loginObj.user?.vendor?.systemsV2,
    accepted: loginObj.user.appAccepted || false,
    acceptedDate: loginObj.user.appAcceptedDate,
    accessRights: loginObj.user?.vendor?.accessRights,
    isFirstLogin: loginObj.isFirstLogin,
    token: loginObj.token,
    guideNotifications: loginObj.user.guideNotifications
  })
}

export const updateUser = (userObj) => {
  const prevUser = getUser()
  const userObjToSave = {
    ...prevUser,
    name: userObj.name ? userObj.name : prevUser.name,
    email: userObj.email ? userObj.email : prevUser.email,
    company: userObj.vendor?.company ? userObj.vendor?.company : prevUser.company,
    companyId: userObj.companyId,
    userId: userObj.userId, // Admin
    systemId: userObj.systemId, // Admin
    systemName: userObj.systemName ? userObj.systemName : null, // Admin
    systemCategory: userObj.systemCategory ? userObj.systemCategory : null, // Admin
    role: userObj.role ? userObj.role : prevUser.role ? prevUser.role : null, // Admin
    appRole: userObj.appRole
      ? userObj.appRole
      : prevUser.appRole
      ? prevUser.appRole
      : null,
    isFirstLogin: userObj.isFirstLogin,
    noNotificationsInquiry:
      userObj.noNotificationsInquiry === undefined
        ? prevUser.noNotificationsInquiry
        : userObj.noNotificationsInquiry || false,

    guideNotifications: userObj.guideNotifications
      ? userObj.guideNotifications
      : prevUser.guideNotifications
      ? prevUser.guideNotifications
      : null
  }

  if (userObj?.vendor?.accessRights) {
    userObjToSave.accessRights = userObj.vendor.accessRights
  }

  if (Object.prototype.hasOwnProperty.call(userObj, 'companyId')) {
    userObjToSave.userId = null
  }

  if (Object.prototype.hasOwnProperty.call(userObj, 'userId')) {
    userObjToSave.companyId = null
  }

  const saved = setUser(userObjToSave)
  return saved
}

export const updateAcceptUser = () => {
  const user = getUser()

  return setUser({ ...user, accepted: true, acceptedDate: new Date() })
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.email
}

export const logout = (callback) => {
  setUser({})
  callback()
}
