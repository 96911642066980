import React, { useMemo } from 'react'

import useGetCategoryName from '../../../hooks/useGetCategoryName'
import useFetchCategories from '../../../hooks/useFetchCategories'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'
import useSystemsOptions from '../../../hooks/useSystemsOptions'

import Filters from '../../shared/filters'

const AdminAllGuidesFilter = ({ onChangeFilters }) => {
  const { allCategoriesMap } = useFetchCategories()
  const { sitesOptions } = useFetchAvailableSites()
  const { getCategoryName } = useGetCategoryName()
  const { systemsOptions } = useSystemsOptions()

  const guideCategoriesMemo = useMemo(() => {
    const categoriesArr = Object.values(allCategoriesMap)

    return categoriesArr.filter((categoryData) => {
      return Object.values(categoryData.locales).some((localeData) => localeData.hasGuide)
    })
  }, [allCategoriesMap])

  const guideCategoriesOptionsMemo = useMemo(() => {
    const unsortedGuideCategories = guideCategoriesMemo.map((categoryData) => {
      const hasSwedishGuide = categoryData.locales?.['sv_SE']?.hasGuide

      if (hasSwedishGuide) {
        return {
          label: getCategoryName(categoryData.DB),
          value: categoryData.DB
        }
      }

      const [localeToUse] = Object.entries(categoryData.locales).find(
        ([locale, localeData]) => {
          return localeData.hasGuide
        }
      )

      return {
        label: getCategoryName(categoryData.DB, false, localeToUse),
        value: categoryData.DB
      }
    })

    return unsortedGuideCategories.sort((a, b) => {
      const aLabel = a.label?.trim()?.toLowerCase() || ''
      const bLabel = b.label?.trim()?.toLowerCase() || ''

      return aLabel.localeCompare(bLabel)
    })
  }, [guideCategoriesMemo, getCategoryName])

  const filtersMemo = useMemo(() => {
    return [
      {
        id: 'fromDate',
        nameTid: 'content:from',
        type: 'datepicker'
      },
      {
        id: 'toDate',
        nameTid: 'content:to',
        type: 'datepicker'
      },
      {
        id: 'formDone',
        nameTid: 'content:done',
        singleValue: true,
        options: [
          { labelTid: 'content:yes', value: true },
          { labelTid: 'content:no', value: false }
        ]
      },
      {
        id: 'site',
        nameTid: 'content:sites',
        options: sitesOptions
      },
      {
        id: 'system',
        nameTid: 'content:system',
        options: systemsOptions,
        searchable: true
      },
      {
        id: 'category',
        nameTid: 'content:category',
        options: guideCategoriesOptionsMemo,
        searchable: true
      }
    ]
  }, [sitesOptions, systemsOptions, guideCategoriesOptionsMemo])

  return <Filters filters={filtersMemo} onChangeFilters={onChangeFilters} />
}

AdminAllGuidesFilter.propTypes = {}

AdminAllGuidesFilter.defaultProps = {}

export default AdminAllGuidesFilter
