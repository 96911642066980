import { gql } from '@apollo/client'

const SYSTEM_SITE_DATA = `
  id
  systemName
  companyName
  customerType
  systemNameUrl
  systemExternalUrl
  systemExternalUrl
  extraBtnLink
  extraBtnText
  introDescription
  introDescriptionWhat
  introDescriptionWho
  systemDescription
  companyDescription
  marketPositionDescription
  whySystemDescription
  whyCompanyDescription
  solvesClientDescription
  goodToKnowDescription
  priceDescription
  price
  visibility
  locale
  site {
    domain
  }
  redirects {
    type
    locale
    from
    to
  }
`

const SYSTEM_COMMON = `
  id
  createdAt
  defaultCategory
  categories
  marketRegions
  languages
  branch
  sector
  support
  supportIncluded
  education
  mobileApps
  priceModel
  freeTrial
  clientCompanySize
  deployment
  systemStrengths
  systemProps
  systemAreas
  mediaAssets {
    id
    type
    subType
    order
    url
    imageUrl
    locales
  }
`

export const SYSTEM_FRAGMENT_ADMIN = `
  ${SYSTEM_COMMON}
  potentialCategories
  systemAreas
  systemGuideProps
  ratingBW
  ratingBoost
  ratingBWRaw
  clientCompanySizeGuide
  vendorId
  active
  systemSiteData {
    ${SYSTEM_SITE_DATA}
    guideBoost
    licenses
    revenue
    revenueDontKnow
    locale
  }
`

export const SYSTEM_FRAGMENT_RESEARCHER = `
  ${SYSTEM_COMMON}
  systemSiteData {
    id
    systemName
    companyName
    customerType
    systemNameUrl
    systemExternalUrl
    systemExternalUrl
    priceDescription
    introDescription
    introDescriptionWhat
    introDescriptionWho
    price
    visibility
    locale
  }
`

export const SYSTEM_FRAGMENT_USER = `
  ${SYSTEM_COMMON}
  systemSiteData {
    ${SYSTEM_SITE_DATA}
  }
`

export const GET_SYSTEMS_USER_GQL = gql`
    query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
        appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
            ${SYSTEM_FRAGMENT_USER}
        }
    }
`

export const GET_SYSTEMS_ADMIN_GQL = gql`
    query SystemsDataAdmin($companyId: ID, $userId: ID, $systemId: ID) {
        appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
            ${SYSTEM_FRAGMENT_ADMIN}
        }
    }
`

export const GET_SYSTEM_USER_GQL = gql`
    query GetSystem($id: ID!) {
        appSystem(id: $id) {
            ${SYSTEM_FRAGMENT_USER}
        }
    }
`

export const GET_SYSTEM_ADMIN_GQL = gql`
    query GetSystem($id: ID!) {
        appSystem(id: $id) {
            ${SYSTEM_FRAGMENT_ADMIN}
        }
    }
`

export const GET_SYSTEM_RESEARCHER_GQL = gql`
    query SystemDataResearcher($id: ID!, $locale: String!) {
        mrGetSystem(id: $id, locale: $locale) {
            ${SYSTEM_FRAGMENT_RESEARCHER}
        }
    }
`

export const GET_SYSTEM_PROPS_GQL = gql`
  query SystemProps {
    systemProps {
      id
      dbName
      category
    }
    systemPropsArea {
      id
      dbName
      category
    }
  }
`

export const FRAGMENT_REVIEW_TEXT = gql`
  fragment ReviewTextsFragment on ReviewData {
    id
    created
    reviewLikeMost
    reviewLikeLeast
    reviewMetExpectations
    reviewBusinessCritical
    reviewTitle
    reviewRole
    reviewCompanySize
    reviewEmailAnonymized
    reviewUsablility
    reviewSupport
    reviewPriceRelation
    reviewFunctions
    reviewIntegrations
    reviewCollaborationSupplier
    reviewClientCustomizing
    reviewDate
    commentAuthor
    commentText
    locale
  }
`

export const GET_SYSTEM_PROPS_ADMIN_GQL = gql`
  query SystemPropsAdmin {
    systemProps {
      id
      dbName
      category
    }
    systemPropsArea {
      id
      dbName
      category
    }
    systemPropsGuide {
      id
      dbName
      category
    }
  }
`
