import React from 'react'
import { useMutation, gql } from '@apollo/client'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { useTranslation } from 'react-i18next'

import useOverlayNotification from '../hooks/useOverlayNotification'
import useFetchAvailableSites from '../hooks/useFetchAvailableSites'

import Button from '../components/shared/button'

const TRIGGER_DEPLOY = gql`
  mutation AppTriggerDeploy($site: String!) {
    appDeploySite(site: $site) {
      id
    }
  }
`

function AdminDeployPage() {
  const { t } = useTranslation('adminPages')
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()
  const { sites, sitesLoading } = useFetchAvailableSites()

  // Save to db first time a box has been toggle
  const [triggerDeploy] = useMutation(TRIGGER_DEPLOY, {
    onError: showErrorNotification,
    onCompleted: showSuccessNotification
  })

  const handleTriggerDeploy = (site) => {
    triggerDeploy({
      variables: {
        site
      }
    })

    // TODO: Trigger new reload of images, to see that it is building.
  }

  const renderDeployButton = (site) => {
    const { defaultLocale } = site
    const countryCode = defaultLocale.split('_')[1].toUpperCase()
    const btnText = `${t('adminDeploy.deploy')} - ${countryCode}`

    return (
      <Button key={defaultLocale} onClick={() => handleTriggerDeploy(countryCode)}>
        {btnText}
      </Button>
    )
  }

  return (
    <>
      <PageHeader pageName={t('adminDeploy.header.title')}>
        <p>{t('adminDeploy.header.subtitle')}</p>
      </PageHeader>

      <PageBody loading={sitesLoading}>
        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-900">
            {t('adminDeploy.triggerDeploy')}
          </h2>

          <div className="mt-4 flex flex-row flex-wrap gap-4">
            <Button onClick={() => handleTriggerDeploy('APP')}>
              {t('adminDeploy.deploy')} - App
            </Button>
          </div>
        </div>

        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-900">
            {t('adminDeploy.triggerDeploy')}
          </h2>

          <div className="mt-4 flex flex-row flex-wrap gap-4">
            {sites.map(renderDeployButton)}
          </div>
        </div>

        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-900">Other triggers</h2>

          <div className="mt-4 flex flex-row flex-wrap gap-4">
            <Button onClick={() => handleTriggerDeploy('REDIRECTS')}>
              Rebuild Redirects
            </Button>
          </div>
        </div>
      </PageBody>
    </>
  )
}

export default AdminDeployPage
