const OPTION_BOOL_TRUE = {
  id: 'true',
  titleTid: 'content:yes',
  text: null
}

const OPTION_BOOL_FALSE = {
  id: 'false',
  titleTid: 'content:no',
  text: null
}

const OPTION_CUS_TYPE_BASIC = {
  id: 'BASIC',
  title: 'BASIC',
  text: null
}

const OPTION_CUS_TYPE_TRIAL = {
  id: 'TRIAL',
  title: 'TRIAL',
  text: null
}

const OPTION_CUS_TYPE_POST_TRIAL = {
  id: 'POST_TRIAL',
  title: 'POST_TRIAL',
  text: null
}

const OPTION_CUS_TYPE_CUSTOMER = {
  id: 'CUSTOMER',
  title: 'CUSTOMER',
  text: null
}

const OPTION_CUS_TYPE_AFFILIATE = {
  id: 'AFFILIATE',
  title: 'AFFILIATE',
  text: null
}

const HIDDEN = {
  id: 'HIDDEN',
  title: 'HIDDEN',
  text: null
}

const SITE_ONLY = {
  id: 'SITE_ONLY',
  title: 'SITE_ONLY',
  text: null
}

const SITE_AND_GUIDE = {
  id: 'SITE_AND_GUIDE',
  title: 'SITE_AND_GUIDE',
  text: null
}

const ARRAY_CUS_TYPES = [
  OPTION_CUS_TYPE_BASIC,
  OPTION_CUS_TYPE_TRIAL,
  OPTION_CUS_TYPE_POST_TRIAL,
  OPTION_CUS_TYPE_CUSTOMER,
  OPTION_CUS_TYPE_AFFILIATE
]

const ARRAY_VISIBILTY_VALUES = [HIDDEN, SITE_ONLY, SITE_AND_GUIDE]

const ARRAY_CUS_SIZES = [
  {
    id: 'MICRO',
    titleTid: 'forms:system.customerSizeMicroTitle',
    textTid: 'forms:system.customerSizeMicroText'
  },
  {
    id: 'SMALL',
    titleTid: 'forms:system.customerSizeSmallTitle',
    textTid: 'forms:system.customerSizeSmallText'
  },
  {
    id: 'MEDIUM',
    titleTid: 'forms:system.customerSizeMediumTitle',
    textTid: 'forms:system.customerSizeMediumText'
  },
  {
    id: 'LARGE',
    titleTid: 'forms:system.customerSizeLargeTitle',
    textTid: 'forms:system.customerSizeLargeText'
  }
]

export const profileFields = Object.freeze({
  systemFields: [
    {
      id: 'marketRegions',
      nameTid: 'forms:system.systemMarketRegionsName',
      type: 'multiple',
      values: []
    },
    {
      id: 'languages',
      nameTid: 'forms:system.systemLanguagesName',
      text: null,
      type: 'multiple',
      values: [
        {
          id: 'SWEDISH',
          titleTid: 'content:languages.swedish',
          text: null
        },
        {
          id: 'DANISH',
          titleTid: 'content:languages.danish',
          text: null
        },
        {
          id: 'NORWEGIAN',
          titleTid: 'content:languages.norwegian',
          text: null
        },
        {
          id: 'FINNISH',
          titleTid: 'content:languages.finnish',
          text: null
        },
        {
          id: 'ENGLISH',
          titleTid: 'content:languages.english',
          text: null
        }
      ]
    },
    // branch: [Branch] @scalarList(strategy: RELATION)
    {
      id: 'branch',
      nameTid: 'forms:system.systemBranchName',
      text: null,
      type: 'multiple',
      display: 'multiple-column',
      values: [
        {
          id: 'ALL',
          titleTid: 'forms:system.branch.ALL',
          text: null
          // type: 'button',
        },
        {
          id: 'MANUFACTURING_PRODUCTION',
          titleTid: 'forms:system.branch.MANUFACTURING_PRODUCTION',
          text: null
        },
        {
          id: 'TRANSPORT_DISTRIBUTION',
          titleTid: 'forms:system.branch.TRANSPORT_DISTRIBUTION',
          text: null
        },
        {
          id: 'GROCERY_STORE',
          titleTid: 'forms:system.branch.GROCERY_STORE',
          text: null
        },
        {
          id: 'CONSULTANT_CONSULTING',
          titleTid: 'forms:system.branch.CONSULTANT_CONSULTING',
          text: null
        },
        {
          id: 'CONSTRUCTION_CRAFTSMEN_PLANT',
          titleTid: 'forms:system.branch.CONSTRUCTION_CRAFTSMEN_PLANT',
          text: null
        },
        {
          id: 'REAL_ESTATE',
          titleTid: 'forms:system.branch.REAL_ESTATE',
          text: null
        },
        {
          id: 'IT',
          titleTid: 'forms:system.branch.IT',
          text: null
        },
        {
          id: 'HOTEL_AND_RESTAURANT',
          titleTid: 'forms:system.branch.HOTEL_AND_RESTAURANT',
          text: null
        },
        {
          id: 'HEALTH_SOCIAL_CARE',
          titleTid: 'forms:system.branch.HEALTH_SOCIAL_CARE',
          text: null
        },
        {
          id: 'SERVICE_AFTER_SALES',
          titleTid: 'forms:system.branch.SERVICE_AFTER_SALES',
          text: null
        },
        {
          id: 'BANKING_FINANCE_INSURANCE',
          titleTid: 'forms:system.branch.BANKING_FINANCE_INSURANCE',
          text: null
        },
        {
          id: 'RETAIL_WHOLESALE',
          titleTid: 'forms:system.branch.RETAIL_WHOLESALE',
          text: null
        },
        {
          id: 'RETAIL_FRANCHISE',
          titleTid: 'forms:system.branch.RETAIL_FRANCHISE',
          text: null
        },
        {
          id: 'PUBLIC_GOVERNMENT_ADMINISTRATION',
          titleTid: 'forms:system.branch.PUBLIC_GOVERNMENT_ADMINISTRATION',
          text: null
        },
        {
          id: 'TELECOM',
          titleTid: 'forms:system.branch.TELECOM',
          text: null
        },
        {
          id: 'EDUCATION',
          titleTid: 'forms:system.branch.EDUCATION',
          text: null
        },
        {
          id: 'ENERGY',
          titleTid: 'forms:system.branch.ENERGY',
          text: null
        },
        {
          id: 'MARKETING_ADVERTISING',
          titleTid: 'forms:system.branch.MARKETING_ADVERTISING',
          text: null
        },
        {
          id: 'HR',
          titleTid: 'forms:system.branch.HR',
          text: null
        },
        {
          id: 'OTHER',
          titleTid: 'forms:system.branch.OTHER',
          text: null
        }
      ]
    },
    // support: [Support] @scalarList(strategy: RELATION)
    {
      id: 'support',
      nameTid: 'forms:system.systemSupportName',
      text: null,
      type: 'multiple',
      values: [
        {
          id: 'WEB',
          titleTid: 'forms:system.supportMethods.WEB',
          text: null
        },
        {
          id: 'PHONE',
          titleTid: 'forms:system.supportMethods.PHONE',
          text: null
        },
        {
          id: 'EMAIL',
          titleTid: 'forms:system.supportMethods.EMAIL',
          text: null
        }
      ]
    },
    {
      id: 'supportIncluded',
      nameTid: 'forms:system.supportIncludedName',
      text: null,
      type: 'single',
      values: [OPTION_BOOL_TRUE, OPTION_BOOL_FALSE]
    },
    {
      id: 'education',
      nameTid: 'forms:system.educationName',
      text: null,
      type: 'multiple',
      values: [
        {
          id: 'WEBINAR',
          titleTid: 'forms:system.educationMethods.WEBINAR',
          text: null
        },
        {
          id: 'DOCUMENT',
          titleTid: 'forms:system.educationMethods.DOCUMENT',
          text: null
        },
        {
          id: 'ONSITEATCUSTOMER',
          titleTid: 'forms:system.educationMethods.ONSITEATCUSTOMER',
          text: null
        }
      ]
    },
    {
      id: 'mobileApps',
      nameTid: 'forms:system.mobileAppsName',
      text: null,
      type: 'multiple',
      values: [
        {
          id: 'IOS',
          titleTid: 'forms:system.supportedAppDevices.IOS',
          text: null
        },
        {
          id: 'ANDROID',
          titleTid: 'forms:system.supportedAppDevices.ANDROID',
          text: null
        },
        {
          id: 'RESPONSIVE',
          titleTid: 'forms:system.supportedAppDevices.RESPONSIVE',
          text: null
        }
      ]
    },
    {
      id: 'priceModel',
      nameTid: 'forms:system.priceModelName',
      text: null,
      type: 'multiple',
      values: [
        {
          id: 'MODULE',
          titleTid: 'forms:system.priceModels.MODULE',
          text: null
        },
        {
          id: 'PACKAGE',
          titleTid: 'forms:system.priceModels.PACKAGE',
          text: null
        },
        {
          id: 'LICENSE',
          titleTid: 'forms:system.priceModels.LICENSE',
          text: null
        }
      ]
    },
    {
      id: 'freeTrial',
      nameTid: 'forms:system.freeTrialName',
      type: 'single',
      values: [OPTION_BOOL_TRUE, OPTION_BOOL_FALSE]
    },
    {
      id: 'clientCompanySize',
      nameTid: 'forms:system.clientCompanySizeName',
      text: '',
      type: 'multiple',
      values: ARRAY_CUS_SIZES
    },
    {
      id: 'deployment',
      nameTid: 'forms:system.deploymentName',
      text: null,
      type: 'multiple',
      values: [
        {
          id: 'SAAS',
          titleTid: 'forms:system.deploymentTypes.SAAS',
          text: null
        },
        {
          id: 'ONPREMISE',
          titleTid: 'forms:system.deploymentTypes.ONPREMISE',
          text: null
        }
      ]
    },
    {
      id: 'sector',
      nameTid: 'forms:system.sectorName',
      textTid: 'forms:system.sectorText',
      type: 'multiple',
      values: [
        {
          id: 'PRIVATE',
          titleTid: 'forms:system.sectors.PRIVATE',
          textTid: 'forms:system.sectors.PRIVATE_TEXT'
        },
        {
          id: 'PUBLIC',
          titleTid: 'forms:system.sectors.PUBLIC',
          textTid: 'forms:system.sectors.PUBLIC_TEXT'
        },
        {
          id: 'OTHER',
          titleTid: 'forms:system.sectors.OTHER',
          text: null
        }
      ]
    },
    {
      id: 'systemStrengths',
      nameTid: 'forms:system.systemStrengthsName',
      textTid: 'forms:system.systemStrengthsText',
      type: 'multiple',
      display: 'multiple-column',
      values: [
        {
          id: 'EASE_OF_USE',
          titleTid: 'forms:system.guideStrengths.EASE_OF_USE',
          text: null
        },
        {
          id: 'INTEGRATION',
          titleTid: 'forms:system.guideStrengths.INTEGRATION',
          text: null
        },
        {
          id: 'EXTENSIVE_PRODUCT_PORTFOLIO',
          titleTid: 'forms:system.guideStrengths.EXTENSIVE_PRODUCT_PORTFOLIO',
          text: null
        },
        {
          id: 'SYSTEM_ADAPTATION',
          titleTid: 'forms:system.guideStrengths.SYSTEM_ADAPTATION',
          text: null
        },
        {
          id: 'MOBILE_SOLUTION',
          titleTid: 'forms:system.guideStrengths.MOBILE_SOLUTION',
          text: null
        },
        {
          id: 'NEW_TECHNOLOGY_INNOVATION',
          titleTid: 'forms:system.guideStrengths.NEW_TECHNOLOGY_INNOVATION',
          text: null
        },
        {
          id: 'SUPPORT_SERVICE',
          titleTid: 'forms:system.guideStrengths.SUPPORT_SERVICE',
          text: null
        },
        {
          id: 'MUCH_EXPERIENCE',
          titleTid: 'forms:system.guideStrengths.MUCH_EXPERIENCE',
          text: null
        },
        {
          id: 'PRICE_MODEL',
          titleTid: 'forms:system.guideStrengths.PRICE_MODEL',
          text: null
        },
        {
          id: 'EASY_IMPLEMENTATION',
          titleTid: 'forms:system.guideStrengths.EASY_IMPLEMENTATION',
          text: null
        },
        {
          id: 'ADVICE',
          titleTid: 'forms:system.guideStrengths.ADVICE',
          text: null
        }
      ]
    }
  ],
  systemProps: [
    {
      id: 'systemProps',
      nameTid: 'forms:system.systemPropsName',
      text: null,
      type: 'json'
    },
    {
      id: 'systemAreas',
      nameTid: 'forms:system.systemAreasName',
      text: null,
      type: 'json'
    }
  ],
  langFields: [
    {
      id: 'systemName',
      nameTid: 'forms:system.systemNameName',
      textTid: 'forms:system.contactUsToChange',
      type: 'text',
      disabled: true
    },
    {
      id: 'companyName',
      nameTid: 'forms:system.companyNameName',
      textTid: 'forms:system.contactUsToChange',
      type: 'text',
      disabled: true
    },
    {
      id: 'introDescriptionWhat',
      nameTid: 'forms:system.introDescriptionWhatName',
      textTid: 'forms:system.introDescriptionWhatText',
      type: 'textarea',
      freeOnly: true
    },
    {
      id: 'introDescriptionWho',
      nameTid: 'forms:system.introDescriptionWhoName',
      textTid: 'forms:system.introDescriptionWhoText',
      type: 'textarea',
      freeOnly: true
    },
    {
      id: 'introDescription',
      nameTid: 'forms:system.introDescriptionName',
      textTid: 'forms:system.introDescriptionText',
      type: 'textarea',
      paid: true
    },
    {
      id: 'systemExternalUrl',
      nameTid: 'forms:system.systemExternalUrlName',
      textTid: 'forms:system.systemExternalUrlText',
      type: 'text'
    },
    {
      id: 'extraBtnText',
      nameTid: 'forms:system.extraBtnTextName',
      textTid: 'forms:system.extraBtnTextText',
      type: 'text',
      paid: true
    },
    {
      id: 'extraBtnLink',
      nameTid: 'forms:system.extraBtnLinkName',
      textTid: 'forms:system.systemExternalUrlText',
      type: 'text',
      paid: true
    },
    {
      id: 'systemDescription',
      nameTid: 'forms:system.systemDescriptionName',
      textTid: 'forms:system.systemDescriptionText',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'whySystemDescription',
      nameTid: 'forms:system.whySystemDescriptionName',
      textTid: 'forms:system.whySystemDescriptionText',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'whyCompanyDescription',
      nameTid: 'forms:system.whyCompanyDescriptionName',
      textTid: 'forms:system.whyCompanyDescriptionText',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'marketPositionDescription',
      nameTid: 'forms:system.marketPositionDescriptionName',
      textTid: 'forms:system.marketPositionDescriptionText',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'solvesClientDescription',
      nameTid: 'forms:system.solvesClientDescriptionName',
      textTid: 'forms:system.solvesClientDescriptionText',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'companyDescription',
      nameTid: 'forms:system.companyDescriptionName',
      text: '',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'goodToKnowDescription',
      nameTid: 'forms:system.goodToKnowDescriptionName',
      textTid: 'forms:system.goodToKnowDescriptionText',
      type: 'wysiwyg',
      paid: true
    },
    {
      id: 'price',
      nameTid: 'forms:system.priceName',
      textTid: 'forms:system.priceText',
      type: 'text'
    },
    {
      id: 'priceDescription',
      nameTid: 'forms:system.priceDescriptionName',
      textTid: 'forms:system.priceDescriptionText',
      type: 'wysiwyg'
    }
  ],
  langFieldsResearcher: [
    {
      id: 'systemName',
      nameTid: 'forms:system.systemNameName',
      type: 'text',
      disabled: true
    },
    {
      id: 'companyName',
      nameTid: 'forms:system.companyNameName',
      type: 'text'
    },
    {
      id: 'introDescriptionWhat',
      nameTid: 'forms:system.introDescriptionWhatName',
      textTid: 'forms:system.introDescriptionWhatText',
      type: 'textarea'
    },
    {
      id: 'introDescriptionWho',
      nameTid: 'forms:system.introDescriptionWhoName',
      textTid: 'forms:system.introDescriptionWhoText',
      type: 'textarea'
    },
    {
      id: 'systemExternalUrl',
      nameTid: 'forms:system.systemExternalUrlName',
      textTid: 'forms:system.systemExternalUrlText',
      type: 'text'
    },
    {
      id: 'introDescription',
      nameTid: 'forms:system.introDescriptionName',
      textTid: 'forms:system.introDescriptionText',
      type: 'textarea'
    }
  ],
  adminFieldsGeneralV2: [
    {
      id: 'ratingBW',
      nameTid: 'yourSystemProfile:admin.adminDataForm.siteRatingName',
      textTid: 'yourSystemProfile:admin.adminDataForm.siteRatingText',
      type: 'number',
      disabled: true
    },
    {
      id: 'ratingBoost',
      nameTid: 'yourSystemProfile:admin.adminDataForm.factorRatingName',
      text: null,
      type: 'number',
      step: '0.01'
    },
    {
      id: 'ratingBWRaw',
      nameTid: 'forms:system.RatingBwRaw',
      type: 'number',
      disabled: true
    },
    {
      id: 'clientCompanySizeGuide',
      nameTid: 'forms:system.clientCompanySizeGuide',
      text: null,
      type: 'multiple',
      values: ARRAY_CUS_SIZES
    },
    {
      id: 'active',
      nameTid: 'forms:system.activeName',
      textTid: 'forms:system.activeText',
      type: 'single',
      values: [OPTION_BOOL_TRUE, OPTION_BOOL_FALSE]
    }
  ],
  adminFieldsCountrySpecific: [
    {
      id: 'visibility',
      nameTid: 'forms:system.hiddenOnSiteInCountryLocale',
      text: null,
      type: 'single',
      values: ARRAY_VISIBILTY_VALUES
    },
    {
      id: 'customerType',
      nameTid: 'forms:system.customerTypeInCountryLocale',
      text: null,
      type: 'single',
      values: ARRAY_CUS_TYPES
    },
    {
      id: 'guideBoost',
      nameTid: 'forms:system.systemGuideBoostInCountryLocale',
      textTid: 'forms:system.systemGuideBoostRange',
      type: 'text'
    },
    {
      id: 'licensesMin',
      nameTid: 'forms:system.licensesMinInCountryLocale',
      text: null,
      type: 'number'
    },
    {
      id: 'licensesMax',
      nameTid: 'forms:system.licensesMaxInCountryLocale',
      text: null,
      type: 'number'
    },
    {
      id: 'revenueMin',
      nameTid: 'forms:system.revenueMinInCountryLocale',
      textTid: 'forms:system.revenueUnit',
      type: 'number'
    },
    {
      id: 'revenueMax',
      nameTid: 'forms:system.revenueMaxInCountryLocale',
      textTid: 'forms:system.revenueUnit',
      type: 'number'
    },
    {
      id: 'revenueDontKnow',
      nameTid: 'forms:system.revenueDontKnowInCountryLocale',
      textTid: null,
      type: 'single',
      values: [OPTION_BOOL_TRUE, OPTION_BOOL_FALSE]
    },
    {
      id: 'systemNameUrl',
      nameTid: 'forms:system.systemNameUrlName',
      forceDisabled: true,
      type: 'text'
    }
  ]
})
