import React, { useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import UserTable from '../components/users/usertable'
import UserForm from '../components/users/userform'
import { useUserContext } from '../contexts'
import useAdminQuery from '../hooks/useAdminQuery'
import { isAdmin, isAppAdmin } from '../utils'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import Button from '../components/shared/button'

import Loader from '../components/loader'

import Tabs from '../components/shared/tabs'

const USERS_QUERY = gql`
  query Users($companyId: ID, $userId: ID) {
    appUsers(companyId: $companyId, userId: $userId) {
      id
      name
      email
      appRole
      disabled
      latestSignedIn
      systemsV2 {
        id
      }
      appLocales
    }
  }
`

const USER_FORM_OPTIONS_QUERY = gql`
  query UsersLoadForm($companyId: ID, $userId: ID) {
    appUsersLoadForm(companyId: $companyId, userId: $userId) {
      appRoles
      systemsV2 {
        id
        systemSiteData {
          id
          systemName
          companyName
          locale
        }
      }
      appLocales
    }
  }
`

function UsersPage() {
  const { user } = useUserContext()
  const { t } = useTranslation('vendorSettings')
  const [activeUsers, setActiveUsers] = useState([])
  const [inactiveUsers, setInactiveUsers] = useState([])
  const [allTabs, setAllTabs] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [userFormOptions, setUserFormOptions] = useState({})
  const [activeTab, setActiveTab] = useState('ACTIVE')
  const [showCreateForm, setShowCreateForm] = useState(false)
  const admin = isAdmin(user?.role)
  const appAdmin = isAppAdmin(user?.appRole)
  const [createUser, setCreateUser] = useState(null)

  const { loading, refetch, data } = useAdminQuery(USERS_QUERY, {
    onError: (error) => {
      setErrorMessage(error.message)
    }
  })

  const { loadingUserForm, data: userFormOptionsData } = useAdminQuery(
    USER_FORM_OPTIONS_QUERY
  )

  useEffect(() => {
    let sortedActiveUsers = []
    let sortedInctiveUsers = []

    if (data) {
      data.appUsers?.forEach((user) => {
        if (user.disabled) {
          sortedInctiveUsers.push(user)
        } else {
          sortedActiveUsers.push(user)
        }
      })

      setActiveUsers(sortedActiveUsers)
      setInactiveUsers(sortedInctiveUsers)

      let tabs = [{ label: t('users.tabs.active'), value: 'ACTIVE' }]

      if (sortedInctiveUsers.length > 0) {
        tabs.push({ label: t('users.tabs.inactive'), value: 'INACTIVE' })
      }

      tabs.push({ label: t('users.tabs.help'), value: 'HELP' })

      setAllTabs(tabs)

      if (createUser !== null) {
        setCreateUser(null)
        setShowCreateForm(false)
      }

      setUserFormOptions(userFormOptionsData?.appUsersLoadForm)
    }
  }, [data, userFormOptionsData, createUser, t])

  const handleTabChange = ({ value }) => {
    setActiveTab(value)
  }

  if (!admin && !appAdmin) {
    return
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>
  }

  if (loading || loadingUserForm) {
    return <Loader />
  }

  return (
    <>
      <PageHeader pageName={t('users.title')} />

      <PageBody>
        <div className="mb-0">
          {!loading && !loadingUserForm && (
            <div className="mb-10">
              <div className="col-span-2">
                <Button
                  size="small"
                  mode={!showCreateForm ? 'primary' : 'default'}
                  onClick={() => setShowCreateForm(!showCreateForm)}
                >
                  {!showCreateForm && <>{t('users.button.create')}</>}
                  {showCreateForm && <>{t('users.button.hide')}</>}
                </Button>
              </div>
            </div>
          )}
          {showCreateForm && !loadingUserForm && (
            <UserForm
              isCreate={true}
              userFormOptions={userFormOptions}
              onCompleteSuccess={refetch}
              setCreateUser={setCreateUser}
            />
          )}
        </div>

        {!loading && !loadingUserForm && (
          <>
            <Tabs tabs={allTabs} activeTab={activeTab} onChange={handleTabChange} />

            {activeTab !== 'HELP' && (
              <UserTable
                users={activeTab == 'INACTIVE' ? inactiveUsers : activeUsers}
                userFormOptions={userFormOptions}
                onCompleteSuccess={refetch}
              />
            )}
            {activeTab === 'HELP' && (
              <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-10">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('users.form.help.title')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {t('users.form.help.description')}
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('users.form.appRole')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <p className="pb-8">{t('users.form.help.appRole.description')}</p>

                        <div className="flex">
                          <div className="flex flex-none w-14 h-14 font-bold">USER</div>
                          <div className="flex flex-initial w-64">
                            {t('users.form.help.appRole.user')}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="flex flex-none w-14 h-14 font-bold">EDITOR</div>
                          <div className="flex flex-initial w-64">
                            {t('users.form.help.appRole.editor')}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="flex flex-none w-14 h-14 font-bold">ADMIN</div>
                          <div className="flex flex-initial w-64">
                            {t('users.form.help.appRole.admin')}
                          </div>
                        </div>
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('users.form.systems')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {t('users.form.help.systems')}
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('users.form.markets')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {t('users.form.help.markets')}
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('users.form.canlogin')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {t('users.form.help.canlogin')}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            )}
          </>
        )}
      </PageBody>
    </>
  )
}

export default UsersPage
